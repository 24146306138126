<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col lg="12" sm="12" id="form">
                  <b-overlay :show="loadIt">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(addInv)" @reset.prevent="reset" autocomplete="off">
                        <b-row>
                          <b-col xs="12" sm="12" md="6" lg="6">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('user_role.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="region.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    disabled
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="6" lg="6">
                            <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('org_pro.service_namel')}} <span class="text-danger">*</span>
                                </template>
                                 <b-form-select
                                    plain
                                    v-model="region.service_id"
                                    :options="serviceNamesList"
                                    id="service_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback  d-block">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="6" lg="6">
                            <ValidationProvider name="Region" vid="region_office_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region_office_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.office')}}<span class="text-danger">*</span>
                                </template>
                                <v-select name="region_office_id"
                                  v-model="region.region_office_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= regionNameList
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                />
                                  <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{ $t('globalTrans.area') }}</legend>
                          <div class="p-2">
                            <b-row>
                              <b-col lg="5" sm="5">
                                <ValidationProvider name="Division"  vid="division_id" rules="required|min_value:1">
                                  <b-form-group
                                      label-for="division_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="region.detailsFormData.division_id"
                                      :options="divisionList"
                                      id="division_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="5" sm="5">
                                <ValidationProvider name="District"  vid="district_id" rules="required|min_value:1">
                                  <b-form-group
                                      label-for="district_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="region.detailsFormData.district_id"
                                      :options="districtList"
                                      id="district_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-right">
                                  <b-button type="submit" class="btn-sm" variant="success"><i class="fa fa-plus" aria-hidden="true"></i>{{$t('globalTrans.add_more')}}</b-button>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </fieldset>
                      </b-form>
                    </ValidationObserver>
                    <ValidationObserver ref="forms" v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" autocomplete="off">
                        <div id="form" >
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.details') }}</legend>
                            <div class="p-2">
                              <b-overlay :show="loadTable">
                                <table class="table" style="width:100%;padding: 5px" border="1">
                                  <thead class="thead">
                                    <tr>
                                      <th style="width:6%">{{ $t('globalTrans.sl_no') }}</th>
                                      <th style="width:10%">{{ $t('globalTrans.division') }}</th>
                                      <th style="width:12%">{{$t('globalTrans.district')}} </th>
                                      <th style="width:8%">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(item, index) in region.details" :key="index">
                                    <b-td>
                                      {{ $n(index + 1) }}
                                    </b-td>
                                    <b-td>
                                      {{ getColumnName($store.state.commonObj.divisionList, item.division_id)}}
                                    </b-td>
                                    <b-td>
                                      {{ getColumnName($store.state.commonObj.districtList, item.district_id)}}
                                    </b-td>
                                    <b-td>
                                      <span class="d-flex text-center">
                                        <b-button type="button" @click="removeItem(index, item)" class="btn btn-sm btn-danger" variant="danger"><i class="fas fa-window-close m-0"></i></b-button>
                                      </span>
                                    </b-td>
                                  </tr>
                                  <b-tr v-if="region.details.length <= 0">
                                    <td colspan="8" class="text-center">
                                        <span class="text-danger"><b>{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</b></span>
                                    </td>
                                  </b-tr>
                                </table>
                              </b-overlay>
                            </div>
                          </fieldset>
                        </div>
                        <b-row class="text-right">
                          <b-col>
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </ValidationObserver>
                  </b-overlay>
                </b-col>
                <!-- <pre>{{region}}</pre> -->
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { regionBadcFertilizerStore, regionBadcFertilizerUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getData()
      this.region.id = tmp.id
      this.region.org_id = tmp.org_id
      this.region.service_id = tmp.service_id
      this.region.region_office_id = tmp.region_office_id
      this.region.details = tmp.details
      this.region.details = tmp.details
    }
    this.serviceNamesList = this.getSevicename()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      region: {
        details: [],
        detailsFormData: {
          division_id: 0,
          district_id: 0
        },
        org_id: 3,
        service_id: 0,
        region_office_id: null
      },
      serviceNamesList: [],
      regionNameList: [],
      districtList: [],
      loadTable: false,
      loadIt: false,
      customError: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'region.service_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.region.region_office_id = null
        this.regionNameList = this.getRegionNameList(newVal)
      }
    },
    'region.detailsFormData.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    }
  },
  methods: {
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    removeItem (index) {
      this.region.details.splice(index, 1)
    },
    async addInv () {
      this.region.details.push(this.region.detailsFormData)
      this.region.detailsFormData = {
        division_id: this.region.detailsFormData.division_id,
        district_id: 0
      }
      this.$refs.form.reset()
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
          return ''
      }
    },
    getSevicename () {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.value === 11 || item.value === 19)
    },
    getRegionNameList (serviceId) {
      let officeType = 0
      if (serviceId === 11) {
        officeType = 72
      } else if (serviceId === 19) {
        officeType = 132
      }
      const tmpList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === officeType)
      return tmpList.map(item => ({ text: item.text, value: item.value, text_en: item.text_en, text_bn: item.text_bn }))
    },
    getRegionData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loadIt = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.region.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${regionBadcFertilizerUpdate}/${this.id}`, this.region)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, regionBadcFertilizerStore, this.region)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
        this.loadIt = false
      } else {
        this.customError = result.errors
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.loadIt = false
      }
    },
    add (stepId) {
      const stepName = {
        step_id: stepId + 1,
        district_id: ''
      }
      this.setDistricts.push(stepName)
    },
    remove (key, stepId) {
      if (this.id) {
        const index = this.region.district_id.indexOf(this.setDistricts[key].district_id)
        if (index > -1) {
          this.region.district_id.splice(index, 1)
        }
      } else {
        this.region.district_id.splice(stepId, 1)
      }
      this.setDistricts.splice(key, 1)
      this.region.district_id.splice(stepId, 1)
    }
  }
}
</script>
